<template>
  <div>
    <BarChart :data="chartData" />
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { Bar } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";

// Register necessary components from Chart.js
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default defineComponent({
  name: "RideVolumeChart",
  components: {
    BarChart: Bar
  },
  props: {
    // Expecting rideData to be an array passed from the parent component
    rideData: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const chartData = ref({
      labels: Array.from({ length: 24 }, (_, i) => `${i}:00`), // Hour labels (0:00, 1:00, ..., 23:00)
      datasets: [
        {
          label: "Number of Rides By Hour",
          data: props.rideData, // Use rideData from the parent component
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1
        }
      ]
    });

    // Watch for changes to rideData prop (if the parent updates the data)
    watch(() => props.rideData, (newData) => {
      chartData.value.datasets[0].data = newData;
    });

    return { chartData };
  }
});
</script>

<style scoped>
/* Optional: Customize the chart styling */
canvas {
  max-width: 100%;
  width: 100%; /* Makes the canvas responsive */
  height: 300px; /* Adjust height if necessary */
}
</style>