<template>
  <div id="app">
    <CSVUploader />
  </div>
</template>

<script>
import CSVUploader from './components/CSVUploader.vue';

export default {
  components: {
    CSVUploader,
  },
};
</script>
