<template>
  <div class="container">
    <h2>ALTR SCHEDULING DEMO</h2>
    <form @submit.prevent="uploadFile" class="upload-form">
      <label>Select Assets and Set Shift Durations:</label>
      <div class="dropdown-container">
        <button type="button" @click="toggleDropdown" class="dropdown-toggle">
          Asset Configuration
        </button>
        <div v-if="showDropdown" class="dropdown">
  <table class="asset-table">
    <thead>
      <tr>
        <th>Asset Name</th>
        <th>Start Time</th>
        <th>End Time</th>
        <th>Break Start</th>
        <th>Break End</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="asset in assets" :key="asset.id">
        <td>
          <label>
            <input
              type="checkbox"
              :value="asset.name"
              checked
              disabled
              class="checkbox"
            />
            {{ asset.name }}
          </label>
        </td>
        <td>
          <input
            type="time"
            v-model="asset.shift_start"
            class="time-input"
          />
        </td>
        <td>
          <input
            type="time"
            v-model="asset.shift_end"
            class="time-input"
          />
        </td>
        <td>
          <input
            type="time"
            v-model="asset.lunch_start"
            class="time-input"
            disabled
          />
        </td>
        <td>
          <input
            type="time"
            v-model="asset.lunch_end"
            class="time-input"
            disabled
          />
        </td>
      </tr>
    </tbody>
  </table>
</div>

      </div>


    <!-- Loading Spinner -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="spinner"></div>
    </div>


      <!-- File Input and Upload Button -->
       <div class="uploader">
      <input type="file" @change="onFileChange" accept=".csv" class="file-input" />
      <button type="submit" class="upload-button">Run Inference</button>
    </div>
    </form>
    <p v-if="message" class="message">{{ message }}</p>
    <ul v-if="errors.length" class="error-list">
      <li v-for="error in errors" :key="error" class="error-item">{{ error }}</li>
    </ul>


        <!-- Using the RideVolume component -->

        <div style="text-align: center;" v-if="ride_volume_arr">
<p style="margin-bottom: 2px; font-weight: bold;">Original Rides by Hour Distribution (Before Inference)</p>
<p style="font-size: small; color: #555;">This illustrates the agent's ride availability challenge based on the current dataset.</p>

          <RideVolumeChart :rideData="ride_volume_arr" />
        </div>


  <!-- Metrics Container -->
  <div class="metrics-container" style="display: flex; gap: 20px; justify-content: space-between;">
    <!-- Means Section -->
    <div v-if="Object.keys(means).length" class="means-display">
      <h3 style="text-align: center;">Calculated Means</h3>
      <table class="means-table">
        <thead>
          <tr>
            <th>Metric</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in means" :key="key">
            <td>{{ key.replace(/_/g, ' ').toUpperCase() }}</td>
            <td>{{ value.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>



    <!-- Table for Parsed Data -->
    <table v-if="parsedData.length" class="data-table">
      <thead>
        <tr>
          <th v-for="(value, key) in parsedData[0]" :key="key">{{ key }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in parsedData" :key="index">
          <td v-for="(value, key) in row" :key="key">{{ value }}</td>
        </tr>
      </tbody>
    </table>


  </div>
</template>

<script>
import axios from "axios";
import RideVolumeChart from '@/components/RideVolumeChart.vue';


export default {
  components: {
    RideVolumeChart // Register the component so it can be used within the template
  },
  data() {
    return {
      file: null,
      message: "",
      errors: [],
      parsedData: [],
      assets: [],
      selectedAssets: [], // Initially empty, will be populated with asset names
      showDropdown: false,
      means: {},
      ride_volume_arr : null,
      isLoading: false, // Add loading state
    };
  },
  methods: {
    async fetchAssets() {
      try {
        const response = await axios.get("https://www.pionir.ai/api/assets/"); // Adjust your API endpoint accordingly
        this.assets = response.data;

        // Convert the shift times to HH:MM:SS format
        this.assets.forEach(asset => {
          asset.shift_start = this.formatTime(asset.shift_start_time);
          asset.shift_end = this.formatTime(asset.shift_end_time);
        });

        // Automatically select all assets by their name
        this.selectedAssets = this.assets.map(asset => asset.name);
      } catch (error) {
        console.log('The Error: ', error);
        this.errors.push("Failed to load assets from the server.");
      }
    },

    formatTime(time) {
      const hours = String(time).padStart(2, '0');
      const minutes = '00';
      const seconds = '00';
      return `${hours}:${minutes}:${seconds}`;
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    onFileChange(event) {
      this.file = event.target.files[0];
    },


    async uploadFile() {
      if (!this.file) {
        this.message = "Please select a file.";
        return;
      }

      if (this.selectedAssets.length === 0) {
        this.message = "Please select at least one asset.";
        return;
      }

      const invalidAssets = this.assets.filter(
        (asset) =>
          this.selectedAssets.includes(asset.name) &&
          (!asset.shift_start || !asset.shift_end)
      );

      if (invalidAssets.length > 0) {
        const invalidAssetNames = invalidAssets.map((asset) => asset.name);
        this.message = `Please set start and end times for: ${invalidAssetNames.join(", ")}`;
        return;
      }

      const selectedAssetDetails = this.assets.filter((asset) =>
        this.selectedAssets.includes(asset.name)
      );

      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("assets", JSON.stringify(selectedAssetDetails));

      // Set loading state to true before making the request
      this.isLoading = true;

      try {
        const response = await axios.post(
          "https://www.pionir.ai/api/upload-csv/",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        this.message = response.data.message;
        this.errors = [];
        this.parsedData = response.data.data;
        this.means = response.data.means;
        this.ride_volume_arr = response.data.rides_volume;
      } catch (error) {
        this.message = error.response?.data?.error || "Error occurred.";
        this.errors = error.response?.data?.details || [];
      } finally {
        // Set loading state to false after the request is complete
        this.isLoading = false;
      }
    },



  },

  created() {
    this.fetchAssets(); // Fetch assets when the component is created
  },
};
</script>

<style scoped>

body, html {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Aligns content to the top */
  width: 80%; /* Set the container width to 80% */
  margin: 0 auto; /* Center the container horizontally */
}

h2 {
  margin-top: 25; /* No margin at top of the heading */
  font-size: 2rem;
  color: #333;
}

/* Form Styling */
.upload-form {
  width: 100%;

  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

label {
  font-weight: bold;
}

input[type="file"],
button {
  margin-top: 10px;
  padding: 10px;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="file"] {
  width: 100%;
}

/* Dropdown Styling */
.dropdown-container {
  position: relative;
}

.dropdown-toggle {
  background-color: #28a745;
  color: white;
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.checkbox {
  margin-right: 10px;
}

/* Table Styling */
.data-table, .means-table {
  margin-top: 20px;
  width: 100%; /* Adjust width to fit container */
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

th {
  background-color: #007bff;
  color: white;
}

td {
  background-color: #f9f9f9;
}

.means-display {
  margin-top: 40px;
}

/* Message and Error List Styling */
.message {
  color: green;
  font-size: 1.2rem;
}

.error-list {
  margin-top: 20px;
  color: red;
  list-style: none;
  padding-left: 0;
}

.error-item {
  margin-bottom: 5px;
}

.asset-table {
  width: 100%; /* Full width of dropdown */
  border-collapse: collapse;
  margin-top: 10px;
}

.asset-table th, .asset-table td {
  padding: 2px;
  text-align: left;
  border: 1px solid #ddd;
}

.asset-table th {
  background-color: #007bff;
  color: white;
}

.asset-table td {
  background-color: #f9f9f9;
}

.asset-table input[type="time"] {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
}

.uploader{
  width: 50%;
  margin: 0 auto;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid #f3f3f3; /* Light grey background */
  border-top: 4px solid #3498db; /* Blue spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
